import { Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator';
import { $axios, getConfig } from '~/utils/Api';

const ID_AUTH_API = process.env.ID_AUTH_API;
@Module({
  namespaced: true,
  stateFactory: true,
})
export default class QuestionnaireModule extends VuexModule {
  questionsPassed: boolean = false;
  formUrl: string = '';

  @VuexMutation
  setPassed(status: boolean) {
    this.questionsPassed = status;
  }

  @VuexMutation
  setFormUrl(formUrl: string) {
    this.formUrl = formUrl;
  }

  get isPassed(): boolean {
    return this.questionsPassed;
  }

  @VuexAction
  async fetch() {
    const { data }: any = await $axios.get(
      `${ID_AUTH_API}/auth/questionnaire?getFormURL=true`,
      getConfig(this.context),
    );
    this.context.commit('setPassed', data.result);
    this.context.commit('setFormUrl', data.formUrl);
  }
}
