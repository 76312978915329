import { Transaction, TransactionStatus } from '~/models/Transaction';
export class OrderHelper {
  static isTransactionInProcess({ status }: Transaction): boolean {
    return (
      status !== TransactionStatus.PROCESSED &&
      status !== TransactionStatus.ERROR &&
      status !== TransactionStatus.TIMEOUT
    );
  }
}
