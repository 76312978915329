import type { Route } from 'vue-router';
import { BrandHelper } from '~/utils/BrandHelper';
import { Brand } from '~/models/Brand';

export interface AuthenticatedContext {
  store: { commit: Function; getters: any; dispatch: Function };
  redirect: (string: string) => any;
  route: Route;
  next: any;
}

export default async function ({ store: { getters, commit, dispatch }, redirect, route, next }: AuthenticatedContext) {
  if (!process.browser) {
    return;
  }

  const isAuth: any = localStorage.getItem('auth');

  if (isAuth) {
    const parsedAuth = JSON.parse(isAuth);
    if (!parsedAuth.authJwt) {
      parsedAuth.authJwt = parsedAuth.userId;
    }
    commit('AuthenticationModule/setQuery', parsedAuth);

    if (route.path !== '/questionnaire' && route.path !== '/questionnaire/') {
      await dispatch('QuestionnaireModule/fetch');
    }

    const isQuestionnaireDone: any = getters['QuestionnaireModule/isPassed'];

    if (!isQuestionnaireDone && route.path !== '/questionnaire' && route.path !== '/questionnaire/') {
      next({
        path: '/questionnaire',
      });
    } else if (isQuestionnaireDone && (route.path === '/questionnaire' || route.path === '/questionnaire/')) {
      redirect('/');
    }
    return;
  }

  if (!getters['AuthenticationModule/isAuthenticated']) {
    const browserLanguage = navigator.language || 'en';
    const preferredLanguage = browserLanguage.split('-')[0];

    if (BrandHelper.getBrand() === Brand.Frontnode && preferredLanguage === 'et') {
      redirect('et/login');
    } else {
      redirect('/login');
    }

    if (BrandHelper.getBrand() === 'blockforia') {
      if (preferredLanguage === 'es') {
        redirect('/es/login');
      } else if (preferredLanguage === 'pt') {
        redirect('/pt/login');
      } else {
        redirect('/login');
      }
    }
  }
}
