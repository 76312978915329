
import { Component, namespace, Vue } from 'nuxt-property-decorator';
import { Profile } from '~/models/Profile';
import { NavItem } from '~/models/Navigation';

const auth = namespace('AuthenticationModule');
const profile = namespace('ProfileModule');

@Component({
  middleware: ['Authenticated'],
})
export default class DefaultLayout extends Vue {
  @auth.Action('logout') logout!: () => void;
  @profile.State('profile') userProfile!: Profile;
  @profile.Action('fetch') fetchUserProfile!: () => Promise<void>;

  private userData: Partial<Profile> = {
    full_name: '',
    email: '',
  };

  private navItems: Array<NavItem> = [
    {
      label: this.$t('overview'),
      icon: 'overview',
      route: '/',
    },
    {
      label: this.$t('buy'),
      icon: 'buy',
      route: '/buy',
    },
    {
      label: this.$t('sell'),
      icon: 'sell',
      route: '/sell',
    },
    {
      label: this.$t('transfer'),
      icon: 'transfer',
      route: '/transfer',
    },
    {
      label: this.$t('transactions'),
      icon: 'transactions',
      route: '/transactions',
    },
    {
      label: this.$t('settings'),
      icon: 'settings',
      route: '/settings',
    },
  ];

  async created() {
    await this.fetchUserProfile();
    const { full_name, email } = this.userProfile;
    this.userData = { full_name, email };
  }
}
