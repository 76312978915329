
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import BrandMixin from '~/mixins/BrandMixin';

@Component({
  layout: 'plain',
})
export default class ErrorLayout extends mixins<BrandMixin>(BrandMixin) {
  @Prop({
    type: Object,
    default: () => null,
  })
  readonly error!: object;
}
