export default class ClaimHelper {
  static decodeJWT(jwt?: string | null): string | undefined {
    if (!jwt) {
      return undefined;
    }
    const base64Url = jwt.split('.')[1];
    const decoded = ClaimHelper.atob(base64Url);
    const parsed = JSON.parse(decoded);
    return parsed.dat;
  }

  static atob(base64Encoded: string): string {
    return Buffer.from(base64Encoded, 'base64').toString();
  }
}
