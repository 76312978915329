import { Module, MutationAction, VuexModule, VuexMutation, VuexAction } from 'nuxt-property-decorator';
import { AuthenticationStatus } from '~/models/AuthenticationStatus';
import { AuthenticationCallbackData } from '~/models/AuthenticationCallbackData';
import ClaimHelper from '~/utils/ClaimHelper';

export interface AuthenticationState {
  query: AuthenticationCallbackData | null;
}

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class AuthenticationModule extends VuexModule implements AuthenticationState {
  query: AuthenticationCallbackData | null = null;

  get authJwt(): string | undefined {
    return this.query?.authJwt || undefined;
  }

  get userId(): string | undefined {
    if (!this.authJwt) {
      return;
    }
    const split = this.authJwt.split('.');
    return split.length > 1 ? split[1] : split[0];
  }

  get hasError(): boolean {
    return !!this.query && !!this.query.error;
  }

  get status(): AuthenticationStatus {
    if (!this.query || this.hasError) {
      return AuthenticationStatus.GUEST;
    }
    if (this.authJwt) {
      return AuthenticationStatus.AUTHENTICATED;
    }
    return AuthenticationStatus.UNKNOWN;
  }

  get isAuthenticated(): boolean {
    return this.status === AuthenticationStatus.AUTHENTICATED;
  }

  get headerValues(): Pick<AuthenticationCallbackData, 'authJwt'> {
    return {
      authJwt: this.authJwt,
    };
  }

  @VuexMutation
  logoutMutation(): void {
    if (process.browser || localStorage) {
      localStorage.clear();
      window.location.href = '/login';
    }
  }

  @MutationAction({ mutate: ['query'] })
  async determineStatus() {
    await Promise.resolve();
    if (!process.browser || !localStorage) {
      return {
        query: null,
      };
    }
    const queryString = localStorage.getItem('auth');
    if (!queryString) {
      return {
        query: null,
      };
    }
    return {
      query: JSON.parse(queryString),
    };
  }

  @VuexAction
  logout() {
    this.context.commit('logoutMutation');
  }

  @VuexMutation
  setQuery(query: AuthenticationCallbackData) {
    if (process.browser && localStorage) {
      localStorage.setItem('auth', JSON.stringify(query));
    }
    if (!query.authJwt) {
      this.query = query;
      return;
    }
    const parsedNamesFromJWT: any = ClaimHelper.decodeJWT(query.authJwt as string);
    this.query = {
      authJwt: query.authJwt,
      ...parsedNamesFromJWT,
    };
  }
}
