import { Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator';
import { $axios, getConfig } from '~/utils/Api';

const VBTC_API = process.env.VBTC_API;

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class RatesModule extends VuexModule {
  rate!: number;

  get currentRate(): number {
    return this.rate;
  }

  @VuexMutation
  setRate(rate: number): void {
    this.rate = rate;
  }

  @VuexAction({ rawError: true })
  async getCurrencyRate(currency: string) {
    try {
      const response = await $axios.get(`${VBTC_API}/currency/${currency}/convert/rate`, getConfig(this.context));

      this.context.commit('setRate', response?.data?.exchange_rate.in_local.rate);
      return response?.data?.exchange_rate.in_local.rate;
    } catch (e) {}
  }
}
