import { render, staticRenderFns } from "./NavigationMobile.vue?vue&type=template&id=80d42696&scoped=true"
import script from "./NavigationMobile.vue?vue&type=script&lang=ts"
export * from "./NavigationMobile.vue?vue&type=script&lang=ts"
import style0 from "./NavigationMobile.vue?vue&type=style&index=0&id=80d42696&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80d42696",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BrandLogo: require('/home/runner/work/dashboard/dashboard/components/BrandLogo.vue').default,HamburgerButton: require('/home/runner/work/dashboard/dashboard/components/navigation/HamburgerButton.vue').default,Icon: require('/home/runner/work/dashboard/dashboard/components/Icon.vue').default,WalletBalance: require('/home/runner/work/dashboard/dashboard/components/WalletBalance.vue').default})
