export enum TransactionType {
  PURCHASE = 'Purchase',
  SALE = 'Sale',
  TRANSFER = 'Transfer',
  EXTERNAL_TRANSFER = 'External Transfer',
}

export enum TransactionStatus {
  CREATED = 'Created',
  PROCESSED = 'Processed',
  ERROR = 'Error',
  TIMEOUT = 'Timeout',
}

export type Transaction = {
  requestId: string;
  transactionId: string;
  time: string;
  status: TransactionStatus;
  fiatAmount: number;
  currency: string;
  btcAmount: string;
  type: TransactionType;
  gains: number;
};
