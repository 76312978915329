import { Plugin } from '@nuxt/types';
import { initializeAxios } from '~/utils/Api';

const isUserLoggedOut = (error: any): boolean =>
  error?.response?.status === 401 && !error?.request?.responseURL.includes('/auth/login');

const accessor: Plugin = ({ $axios, store }) => {
  $axios.onResponseError((error: any) => {
    // if got 401 Unauthorized from any endpoint except the login endpoint
    // logout user
    if (isUserLoggedOut(error)) {
      store.commit('AuthenticationModule/logoutMutation');
    }
  });

  initializeAxios($axios);
};

export default accessor;
