export class DownloadHelper {
  // A secure function to download a blob and to give it a filename.
  // From here: https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
  public static saveFile(blob: Blob, filename: string) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      // Not appending the a-element to the doc leads to issues on old firefox browser versions.
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      // Not using the timeout can lead to errors on old firefox versions here.
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
}
