import { Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator';

export enum ToastIcon {
  CopyPaste = 'CopyPaste',
  Checkmark = 'Checkmark',
  Close = 'Close',
}

export enum ToastStyle {
  Info = 'Info',
  Success = 'Success',
  Error = 'Error',
}

export interface ToastOptions {
  message: string;
  title: string;
  duration?: number;
  style?: ToastStyle;
  icon?: ToastIcon;
}

export enum ToastStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface ToastSate {
  message: string | null;
  title: string | null;
  icon: ToastIcon | null;
  style: ToastStyle | null;
  status: ToastStatus;
}

export type ShowToastFunction = (options: ToastOptions) => Promise<void>;

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class ToastModule extends VuexModule implements ToastSate {
  message: string | null = null;
  title: string | null = null;
  icon: ToastIcon | null = null;
  style: ToastStyle | null = null;
  status = ToastStatus.Inactive;

  @VuexMutation
  hide() {
    this.message = null;
    this.title = null;
    this.icon = null;
    this.style = null;
    this.status = ToastStatus.Inactive;
  }

  @VuexMutation
  setOptions({ message, title, style = ToastStyle.Success, icon = ToastIcon.Checkmark }: ToastOptions) {
    this.message = message as string;
    this.title = title as string;
    this.icon = icon;
    this.style = style;
    this.status = ToastStatus.Active;
  }

  @VuexAction
  async showMessage(options: ToastOptions): Promise<void> {
    const duration = options.duration || 3000;
    this.context.commit('setOptions', options);
    await new Promise((resolve) => setTimeout(resolve, duration));
    this.context.commit('hide');
  }
}
