import { Context } from '@nuxt/types';

export default function (ctx: Context) {
  try {
    const payload = ctx.route.fullPath.includes('transactions')
      ? {
          page: 1,
          limit: 50,
        }
      : {
          page: 1,
          limit: 4,
        };
    return ctx.store.dispatch('VirtualOrderModule/fetchTransactions', payload);
  } catch (e) {
    console.error(e);
  }
}
