import { NuxtAxiosInstance } from '@nuxtjs/axios';

// eslint-disable-next-line import/no-mutable-exports
let $axios: NuxtAxiosInstance;

export const initializeAxios = (axiosInstance: NuxtAxiosInstance) => {
  $axios = axiosInstance;
};

export { $axios };

export const createHeaders = (token: string): any => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

export const createHeadersWithGetters = (rootGetters: any, shouldReduceJWT = false): any => {
  const { authJwt } = rootGetters['AuthenticationModule/headerValues'];
  return shouldReduceJWT ? createHeaders(authJwt.split('.')[1]) : createHeaders(authJwt);
};

export const getConfig = ({ rootGetters }: any, shouldReduceJWT = false): any => ({
  headers: createHeadersWithGetters(rootGetters, shouldReduceJWT),
});
