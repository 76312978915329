import { Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator';
import { Profile } from '~/models/Profile';
import { UserSession } from '~/models/UserSession';
import { $axios, getConfig } from '~/utils/Api';

const ID_AUTH_API = process.env.ID_AUTH_API;

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class ProfileModule extends VuexModule {
  profile: Profile | null = null;
  lastUserSessions: Array<UserSession> = [];

  get defaultName(): string | undefined {
    return this.profile?.given_name;
  }

  @VuexMutation
  setProfile(profile?: Profile) {
    this.profile = profile || null;
  }

  @VuexMutation
  setLastUserSessions(userSessions: Array<UserSession>) {
    this.lastUserSessions = userSessions;
  }

  @VuexAction({ rawError: true })
  async fetch() {
    const { data: profile }: { data: Partial<Profile> } = await $axios.get(
      `${ID_AUTH_API}/auth/user`,
      getConfig(this.context),
    );
    this.context.commit('setProfile', profile);
  }

  @VuexAction
  async update(updateProfileData: Partial<Profile>) {
    await $axios.put(`${ID_AUTH_API}/auth/user`, updateProfileData, getConfig(this.context));
    this.context.dispatch('fetch');
  }

  @VuexAction({ rawError: true })
  async fetchLastUserSessions() {
    const { data: userSessions }: { data: Array<UserSession> } = await $axios.get(
      `${ID_AUTH_API}/auth/user/sessions`,
      getConfig(this.context),
    );
    if (userSessions) {
      this.context.commit('setLastUserSessions', userSessions);
    }
  }
}
