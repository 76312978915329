import Vuex from 'vuex';
import AuthenticationModule from '~/store/AuthenticationModule';
import ProfileModule from '~/store/ProfileModule';
import ToastModule from '~/store/ToastModule';

export const options = {
  modules: {
    AuthenticationModule,
    ProfileModule,
    ToastModule,
  },
  actions: {
    async nuxtServerInit({ dispatch }: { dispatch: Function }) {
      await dispatch('AuthenticationModule/determineStatus');
    },
  },
};

export const createStoreWithOptions = <S>(options: any) =>
  // eslint-disable-next-line import/no-named-as-default-member
  new Vuex.Store<S>(options);

export function createStore() {
  return createStoreWithOptions(options);
}
