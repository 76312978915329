import { Context } from '@nuxt/types';
import { BrandHelper } from '~/utils/BrandHelper';
import { Brand } from '~/models/Brand';

export default function ({ route, redirect }: Context) {
  const browserLanguage: string = navigator.language || 'en';
  const preferredLanguage: string = browserLanguage.split('-')[0];

  if (BrandHelper.getBrand() === Brand.Frontnode) {
    if (preferredLanguage === 'et' && !route.path.startsWith('/et')) {
      const targetPath = '/et' + (route.path !== '/' ? route.fullPath : '');
      return redirect(targetPath);
    }
  }

  if (BrandHelper.getBrand() === Brand.Blockforia) {
    if (preferredLanguage === 'es' && !route.path.startsWith('/es')) {
      const targetPath = '/es' + (route.path !== '/' ? route.fullPath : '');
      return redirect(targetPath);
    } else if (preferredLanguage === 'pt' && !route.path.startsWith('/pt')) {
      const targetPath = '/pt' + (route.path !== '/' ? route.fullPath : '');
      return redirect(targetPath);
    }
  }
}
