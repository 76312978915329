interface AuthenticatedContext {
  store: { commit: Function; getters: any; dispatch: Function };
  redirect: (string: string) => any;
  query: any;
  app: any;
  route: any;
}

export default function ({ store: { commit, dispatch }, query, redirect }: AuthenticatedContext) {
  if (!process.browser) {
    return;
  }
  if (query.err) {
    redirect(`/login`);
    return;
  }
  if (!query.authJwt) {
    handleNoAuthJWTInQuery(commit, dispatch);
    return;
  }
  handleAuthResponse(query, commit, dispatch);
}

const handleNoAuthJWTInQuery = (commit: Function, dispatch: Function) => {
  const authData = localStorage.getItem('auth');
  if (!authData) {
    return;
  }
  const parsedAuthData = JSON.parse(authData);
  if (!parsedAuthData.authJwt) {
    parsedAuthData.authJwt = parsedAuthData.userId;
  }
  commit('AuthenticationModule/setQuery', parsedAuthData);
  dispatch('QuestionnaireModule/fetch');
};

const handleAuthResponse = (query: any, commit: Function, dispatch: Function) => {
  const extendedQuery = {
    ...query,
    authType: 'bankid',
  };
  localStorage.setItem('auth', extendedQuery);
  commit('AuthenticationModule/setQuery', extendedQuery);
  dispatch('QuestionnaireModule/fetch');
};
