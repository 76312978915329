import Vue from 'vue';
import vSelect from 'vue-select';

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: (createElement) =>
      createElement('svg', {
        domProps: {
          innerHTML:
            '<path xmlns="http://www.w3.org/2000/svg" d="M5.00005 3.87852L8.71255 0.166016L9.77305 1.22652L5.00005 5.99952L0.227051 1.22652L1.28755 0.166016L5.00005 3.87852Z" fill="#5A5968"/>',
        },
        attrs: {
          width: '10',
          height: '6',
        },
      }),
  },
});

Vue.component('VSelect', vSelect);
