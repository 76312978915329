
import { Component, mixins, namespace } from 'nuxt-property-decorator';
import BrandMixin from '~/mixins/BrandMixin';

const virtualOrderModule = namespace('VirtualOrderModule');

@Component({})
export default class WalletBalance extends mixins<BrandMixin>(BrandMixin) {
  @virtualOrderModule.Getter('getWalletAmount') walletAmount!: number;
  @virtualOrderModule.Getter('getWalletAmountFiat') walletAmountFiat!: number;
}
