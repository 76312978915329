var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"desktop-navigation"},[_c('div',{staticClass:"top-menu"},[_c('div',[_c('nuxt-link',{staticClass:"logo-link",attrs:{"to":"/"}},[_c('BrandLogo')],1)],1),_vm._v(" "),_c('nav',[_c('ul',{staticClass:"menu",attrs:{"role":"list"}},_vm._l((_vm.navItems.filter((item) => item.icon !== 'settings')),function(item){return _c('li',{key:item.icon},[_c('nuxt-link',{staticClass:"nav-item",class:[_vm.removeLocalePrefix(_vm.$route.path) === item.route ? 'active' : ''],attrs:{"to":item.route}},[_c('Icon',{class:[
                _vm.removeLocalePrefix(_vm.$route.path) === item.route
                  ? 'icon--brand-color-primary-2'
                  : 'icon--brand-color-secondary-1',
              ],attrs:{"name":item.icon}}),_vm._v("\n            "+_vm._s(item.label)+"\n          ")],1)],1)}),0)])]),_vm._v(" "),_c('div',{staticClass:"bottom-menu"},[_c('nav',[_c('nuxt-link',{staticClass:"nav-item customer-menu-item",attrs:{"to":'/profile'}},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.userData.full_name))]),_vm._v(" "),_c('span',{staticClass:"email"},[_vm._v(_vm._s(_vm.userData.email))])]),_vm._v(" "),_c('nuxt-link',{staticClass:"nav-item settings",class:[_vm.removeLocalePrefix(_vm.$route.path) === _vm.settingsItem.route ? 'active' : ''],attrs:{"to":_vm.settingsItem.route}},[_c('Icon',{class:[
            _vm.removeLocalePrefix(_vm.$route.path) === _vm.settingsItem.route
              ? 'icon--brand-color-primary-2'
              : 'icon--brand-color-secondary-1',
          ],attrs:{"name":_vm.settingsItem.icon}}),_vm._v("\n        "+_vm._s(_vm.settingsItem.label)+"\n      ")],1)],1),_vm._v(" "),_c('button',{staticClass:"nav-item logout-button",attrs:{"data-test":"logout"},on:{"click":_vm.logoutCallback}},[_c('Icon',{staticClass:"icon--brand-color-secondary-1",attrs:{"name":"logout"}}),_vm._v("\n      "+_vm._s(_vm.$t('logout'))+"\n    ")],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }